import {Timestamp} from "firebase/firestore";

export enum MessageStatus {
    UNREAD = 'unread',
    READ = 'read',
    DELETED = 'deleted',
}

export enum MessageType {
    INFO = 'info',
    YES_NO_QUESTION = 'yes-no-question'
}

class Message {

    id: string;
    type: MessageType;
    title: string;
    text: string;
    date: Date;
    status: MessageStatus;
    unsubscribe?: string;
    answer?: string;

    constructor(id: string, type: MessageType, title: string, text: string, date: Date,
                status?: MessageStatus, unsubscribe?: string, answer?: string) {
        this.id = id;
        this.type = type;
        this.title = title;
        this.text = text;
        this.date = date;
        this.status = !!status ? status : MessageStatus.UNREAD;
        this.unsubscribe = unsubscribe;
        this.answer = answer;
    }

    toString() {
        return this.text;
    }
}

// Firestore data converted
export const messageConverter = {
    fromObject: (id: string, data: {
        type: string,
        title: string,
        text: string,
        date: Timestamp,
        status: string,
        unsubscribe?: string,
        answer?: string
    }) => {
        return new Message(id, data.type as MessageType, data.title, data.text, data.date.toDate(),
            data.status as MessageStatus, data.unsubscribe, data.answer);
    }
}

export default Message;