import React, {useEffect, useMemo} from "react";
import {ColumnDef} from "@tanstack/react-table";
import Stripe from 'stripe';
import TableWithPages from "../../../components/TableWithPages";
import {int} from "@zxing/library/es2015/customTypings";
import {dateToString} from "../../../utils/Utils";
import {Button, Modal} from "react-bootstrap";


const StripeCustomersModal = (props: {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    assign: (name: string, email: string) => void;
}) => {

    const [customers, setCustomers] = React.useState([]);
    // const [lastCustomerId, setLastCustomerId] = React.useState<string>();
    const [limit, setLimit] = React.useState<number>(100);
    const [selectedIndex, setSelectedIndex] = React.useState<string>();
    const [selectedCustomer, setSelectedCustomer] = React.useState<{ name: string, email: string }>();

    useEffect(() => {
        const stripe = new Stripe('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');
        const params = {limit: limit};
        // if (!!lastCustomerId) {
        //     params['starting_after'] = lastCustomerId;
        // }
        stripe.customers.list(params).then((response) => {
            setCustomers(response.data);
        })
    }, [limit]);  // lastCustomerId, limit

    useEffect(() => {
        if (!selectedIndex) return;
        const index = parseInt(selectedIndex);
        if (index >= 0 && index < customers.length) {
            setSelectedCustomer(customers[selectedIndex]);
        }
    }, [customers, props, selectedIndex]);

    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            accessorKey: 'id',
            header: '',
            cell: info => <input type="radio" name="customerId" readOnly
                                 checked={info.row.id === selectedIndex}/>,
        },
        {
            accessorKey: 'email',
            header: 'Email',
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'created',
            header: 'Created On',
            cell: info => dateToString(new Date(1000 * (info.getValue() as int)))
        }
    ], [selectedIndex]);

    return (
        <Modal show={props.showModal} onHide={() => props.setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Select Stripe Customer</Modal.Title>
            </Modal.Header>
            <Modal.Body className="small">
                <TableWithPages data={customers} columns={columns} setSelectedIndex={setSelectedIndex}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.setShowModal(false)}>Cancel</Button>
                <Button disabled={!selectedCustomer} onClick={() => {
                    if (!!selectedCustomer) {
                        props.assign(selectedCustomer.name, selectedCustomer.email);
                        props.setShowModal(false);
                    }
                }}>Assign</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default StripeCustomersModal;