import {Outlet} from 'react-router-dom';
import {Container, Row} from "react-bootstrap";
import Notification from "../components/Notification";

const Layout = () => {
    return (
        <Notification>
            <Container>
                <Row>
                    <Outlet/>
                </Row>
            </Container>
        </Notification>
    )
}

export default Layout;