import React, {useContext, useEffect, useState} from 'react';
import Column from "../../components/Column";
import {
    Accordion,
    AccordionContext,
    Badge,
    ButtonGroup,
    Card,
    Stack,
    ToggleButton,
    useAccordionButton
} from "react-bootstrap";
import DOMPurify from 'dompurify';
import {
    getUserMessages,
    updateMessageStatus,
    updateMessage as updateMessageInFirebase,
    deleteReminder
} from "../../firebase";
import {useUserAuth} from "../../contexts/UserAuthContext";
import Message, {MessageStatus, MessageType} from "../../models/Message";
import {dateToString} from "../../utils/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBellSlash, faChevronDown, faChevronUp, faXmark} from "@fortawesome/free-solid-svg-icons";
import {captureException} from "@sentry/react";
import Button from "react-bootstrap/Button";

const Messages = () => {

    const [messages, setMessages] = useState<Array<Message>>([]);
    const {user} = useUserAuth();
    const purify = DOMPurify(window);

    useEffect(() => {
        getUserMessages(user.uid)
            .then((messages) => setMessages(messages));
    }, [user]);

    const CustomHeader = (props: {
        eventKey: string;
        date: Date;
        title: string;
        isRead: boolean;
        unsubscribe?: string;
        onClick: () => void;
        onClose: () => void;
    }) => {

        const handleClick = useAccordionButton(props.eventKey, props.onClick);
        const {activeEventKey} = useContext(AccordionContext);

        return (
            <div onClick={handleClick}>
                <Card.Subtitle className="d-flex justify-content-between">
                    <div>
                        {!props.isRead && (
                            <Badge className="me-1">New</Badge>
                        )}
                        {dateToString(props.date, true)}
                        <FontAwesomeIcon icon={activeEventKey === props.eventKey ? faChevronUp : faChevronDown}
                                         className="ms-2"/>
                    </div>
                    <Stack direction="horizontal" className="gap-md-3" gap={1}>
                        {!!props.unsubscribe && (
                            <Button variant="outline-dark" className="px-1 py-0 border-0"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        if (!!props.unsubscribe)
                                            unsubscribe(props.unsubscribe, props.title);
                                    }}>
                                <FontAwesomeIcon icon={faBellSlash}/>
                            </Button>
                        )}
                        <Button onClick={props.onClose} variant="outline-dark" className="px-1 py-0 border-0">
                            <FontAwesomeIcon icon={faXmark}/>
                        </Button>
                    </Stack>
                </Card.Subtitle>
                <Card.Text>
                    <span className="fw-bold">{props.title}</span>
                </Card.Text>
            </div>
        )
    }

    function setMessageStatus(message: Message, status: MessageStatus) {
        message.status = status;
        updateMessageStatus(user.uid, message.id, status)
            .catch((error) => {
                const msg = `Cannot set status of message ${message?.id} to ${status}: ${error}`;
                console.log(msg);
                captureException(msg);
            });
    }

    function updateMessage(messageId: string, data: { [key: string]: any }) {
        // Update local message
        const updatedMessages = [];
        for (const message of messages) {
            if (message.id === messageId) {
                Object.assign(message, data);
            }
            updatedMessages.push(message);
        }
        setMessages(updatedMessages);

        // Update firebase message
        updateMessageInFirebase(user.uid, messageId, data)
            .catch((error) => {
                const msg = `Cannot add ${data} message ${messageId}: ${error}`;
                console.error(msg);
                captureException(msg);
            });
    }

    function unsubscribe(reminderId: string, reminderName: string) {
        if (window.confirm(`Unsubscribe from "${reminderName}"?`)) {
            deleteReminder(reminderId)
                .then(() => {
                    alert(`You are unsubscribed from "${reminderName}"`);
                })
                .catch((error) => {
                    console.error(`Could not delete reminder ${reminderId}:`, error);
                    captureException(`Could not delete reminder ${reminderId}: ${error}`);
                });
        }
    }

    function clearAllMessages() {
        if (window.confirm('Delete all messages?')) {
            messages.forEach((message) => setMessageStatus(message, MessageStatus.DELETED));
            setMessages([]);
        }
    }

    return (
        <Column>
            <Stack className="py-1 py-md-3 gap-md-3" gap={1}>
                <h3 className="d-flex w-100">
                    Messages&nbsp;
                    {!!messages && messages.length > 0 && `(${messages.length})`}
                    <Button size="sm" variant="outline-dark" className="ms-auto" onClick={clearAllMessages}>
                        Clear All
                    </Button>
                </h3>
                <Accordion>
                    {!!messages && messages.map((message, index) => (
                        <Card key={index.toString()} className="small mb-1 mb-md-3">
                            <Card.Body>
                                <CustomHeader eventKey={index.toString()} date={message.date} title={message.title}
                                              isRead={message.status !== MessageStatus.UNREAD}
                                              unsubscribe={message.unsubscribe}
                                              onClick={() => {
                                                  if (message.status === MessageStatus.UNREAD) {
                                                      setMessageStatus(message, MessageStatus.READ);
                                                  }
                                              }}
                                              onClose={() => {
                                                  setMessageStatus(message, MessageStatus.DELETED);
                                                  setMessages(messages.filter(x => x !== message));
                                              }}/>
                                <Accordion.Collapse eventKey={index.toString()}>
                                    <>
                                        <Card.Text>
                                            <div dangerouslySetInnerHTML={{__html: purify.sanitize(message.text)}}/>
                                        </Card.Text>
                                        {message.type === MessageType.YES_NO_QUESTION && (
                                            <ButtonGroup className="w-100">
                                                <ToggleButton id='yes' value='yes' type='radio'
                                                              variant='outline-dark'
                                                              size='sm' checked={message.answer === 'yes'}
                                                              onChange={() => updateMessage(message.id, {answer: 'yes'})}>
                                                    Yes
                                                </ToggleButton>
                                                <ToggleButton id='no' value='no' type='radio' variant='outline-dark'
                                                              size='sm' checked={message.answer === 'no'}
                                                              onChange={() => updateMessage(message.id, {answer: 'no'})}>
                                                    No
                                                </ToggleButton>
                                            </ButtonGroup>
                                        )}
                                        {!!(message.unsubscribe) && (
                                            <p className="mt-1 mt-md-3 mb-0 text-center">
                                                <Card.Link role="button" onClick={(event) => {
                                                    event.preventDefault();
                                                    if (!!(message.unsubscribe)) {
                                                        unsubscribe(message.unsubscribe, message.title);
                                                    }
                                                }}>Unsubscribe</Card.Link> from this reminder
                                            </p>
                                        )}
                                    </>
                                </Accordion.Collapse>
                            </Card.Body>
                        </Card>
                    ))}
                </Accordion>
            </Stack>
        </Column>
    )
}

export default Messages;