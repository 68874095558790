import {captureEvent} from "@sentry/react";
import moment from "moment/moment";

export function getTestStatus(test) {
    if (!test) return null;

    let status;
    try {
        if (test.hasOwnProperty('testId'))
            status = 'Barcode scanned';
        if (test.hasOwnProperty('sampleMass'))
            status = 'Received by lab';
        if (test.hasOwnProperty('concentrations'))
            status = 'Analyzed by lab';
    } catch (error) {
        console.error(error);
        captureEvent(error);
    }
    return status;
}

export function trim(string, length = 80) {
    if (!string) return string;
    if (string.length > length)
        string = string.substring(0, length - 3) + '...';
    return string;
}

export function dateToString(date, showTime) {
    let format = 'YYYY-MM-DD';
    if (showTime) {
        format += ' h:mm A';
    }
    return moment(date).format(format);
}