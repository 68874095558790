import React, {useEffect} from 'react';
import toast, {Toaster} from 'react-hot-toast';
import {auth, onMessageListener, requestForToken, addMessagingToken} from "../firebase";

const Notification = ({children}: {
    children: React.ReactNode;
}) => {
    const [notification, setNotification] = React.useState<{title: string, body: string}>();
    const notify = () => toast(<ToastDisplay/>);

    function ToastDisplay() {
        return (
            <div>
                <p><b>{notification?.title}</b></p>
                <p>{notification?.body}</p>
            </div>
        )
    }

    useEffect(() => {
        if (!!notification?.title) {
            notify();
        }
    }, [notification, notify]);

    onMessageListener()
        .then((payload) => {
            setNotification({
                title: payload?.notification?.title,
                body: payload?.notification?.body,
            })
        })
        .catch((error) => {
            console.log('Failed notification', error);
        })

    return (
        <>
            <Toaster/>
            {children}
        </>
    )
}

export default Notification;